import React from 'react';
import styled from 'styled-components';
import {Input, Button} from 'antd';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import ThemeContext from '../../Theme';
import {Home} from '@styled-icons/material';

function QrCodeDisplay(props) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [storeId, setStoreId] = React.useState('');
  const [myNumber, setMyNumber] = React.useState('');
  const [store, setStore] = React.useState(null);
  const theme = React.useContext(ThemeContext);

  React.useEffect(() => {
    let _sid = getParameterByName('sid');
    if (_sid && _sid.length === 8) {
      setStoreId(_sid);
    }
  }, []);

  async function onSearchStore() {
    if (!storeId) {
      return;
    }

    try {
      actions.setLoading(true);
      let s = await actions.fetchStoreById(storeId);
      if (s && s.isActivated) {
        setStore(s);
      }
    } catch (ex) {
      console.warn(ex);
    } finally {
      actions.setLoading(false);
    }
  }

  async function onSubscribe() {
    if (!user) {
      alert('must login first!');
      return;
    }

    if (store && !store.enableLine) {
      return;
    }

    try {
      actions.setLoading(true);
      await actions.subscribeToStore({
        store: store.id,
        myNumber: parseInt(myNumber, 10),
      });
      alert('註冊成功! [' + store.name + '] ' + parseInt(myNumber, 10) + ' 號');
      setTimeout(() => navigate('/store?sid=' + store.id), 1000);
    } catch (ex) {
      console.warn(ex);
    } finally {
      actions.setLoading(false);
    }
  }

  return (
    <ContentWrapper>
      <Col
        style={{
          margin: 20,
          transform: 'translateY(-40px)',
          border: '6px solid ' + theme.secondary,
          borderRadius: 6,
          boxShadow:
            '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
        }}>
        <Col style={{backgroundColor: '#A9F8EA', padding: '8px 20px'}}>
          <h2 style={{color: 'black', marginBottom: 8}}>
            FreeStay Line 到號通知
          </h2>
          <Row style={{marginBottom: 20}}>
            <Input
              style={{flex: 1}}
              value={storeId}
              placeholder="請輸入 8 碼數字店家編號"
              onChange={(e) => setStoreId(e.target.value)}
              type="number"
            />
            <Button style={{margin: '0px 10px'}} onClick={onSearchStore}>
              加入
            </Button>
            <Button
              style={{margin: '0px 10px'}}
              onClick={() => {
                setStoreId('');
                setStore(null);
              }}>
              清除
            </Button>
          </Row>
        </Col>

        {!store && (
          <Col>
            <br />
            使用步驟：
            <div style={{alignSelf: 'flex-start'}}>
              1. 將 FreeStay 加入 Line 好友 ({' '}
              <a
                href="https://lin.ee/GSxHj2I"
                target="_blank"
                style={{color: 'green'}}
                rel="noreferrer">
                @500qhhs
              </a>{' '}
              )<br />
              2. 在上方輸入店號後點選「加入」後輸入您的等待號碼
              <br />
              3. 接著我們的 FreeStay Line 機器人就會通知您到號資訊囉！
            </div>
            <br />
          </Col>
        )}

        {store && (
          <>
            <Col
              style={{
                padding: '20px 0px',
                backgroundColor: 'white',
                alignSelf: 'stretch',
              }}>
              <label>店家名稱</label>
              <h2>{store.name}</h2>
              <label>目前號碼</label>
              <div style={{fontSize: 64, fontWeight: 'bold'}}>
                {store.current}
              </div>
              <a
                href={'/store?sid=' + store.id}
                target="_blank"
                rel="noreferrer">
                <Home size={32} color={theme.secondary} />
              </a>
            </Col>
            {user && (
              <Row
                style={{
                  padding: 40,
                  alignSelf: 'stretch',
                  backgroundColor: '#ddd',
                }}>
                <Input
                  style={{flex: 1}}
                  value={myNumber}
                  type="number"
                  placeholder="請輸入您的排隊號碼"
                  onChange={(e) => setMyNumber(e.target.value)}
                />

                <Button
                  style={{margin: '0px 10px'}}
                  onClick={onSubscribe}
                  disabled={!store.enableLine}>
                  註冊通知
                </Button>
              </Row>
            )}
          </>
        )}
      </Col>
    </ContentWrapper>
  );
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  min-height: calc(100vh - 64px - 50px);
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => props.css || ''};
`;

export default QrCodeDisplay;
